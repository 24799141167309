// styles
import styled from "@emotion/styled";

export const CommentList = styled.ul`
  width: 100%;

  border-bottom: 2px solid #d0d7de;
`;

export const CommentItem = styled.li``;
