// styles
import styled from "@emotion/styled";
import { color } from "@/assets/styles";

export const IssueBodyLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0.75rem;

  border-bottom: 1px solid ${color.g200};
`;
